import { Component, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import { mixins } from 'vue-class-component';
import {
  getConfigEnv, getComponent, getView, sleep, isGoodService,
} from '@/utils/helpers';
import Multiselect from 'vue-multiselect';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import tasqsListModule from '@/store/modules/tasqsListModule';
import DataLoading from '@/lib/mixins/dataLoading';
import TasqMixin from '@/lib/mixins/TasqMixin';
import tasqSignalsModule from '@/store/modules/tasqSignalsModule';
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';
import accountModule from '@/store/modules/accountModule';
import assetsModule from '@/store/modules/assetsModule';
import tasqActionsModule from '@/store/modules/tasqActionsModule';
import workflowModule from '@/store/modules/workflowModule';
import tasqFeedbackModule from '@/store/modules/tasqFeedbackModule';
import DateRangePicker from 'vue2-daterange-picker';
// you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import workspaceModule from '@/store/modules/workspaceModule';
import { convertUTCDateToLocalDate } from '@/utils/dates';
import { getNameByEmail } from '@/utils/users';
import { datadogRum } from '@datadog/browser-rum';
import {
  preloadData,
} from '@/utils/preloadOfflineData';
import {
  ALL_TASQS_LIST_ITEM,
  SHOW_ALERT,
  TASQ_OFF_TARGET_TYPE,
  TASQ_WAITING_ON_LIST,
  WELL_CLICK_EVENT,
  SHOW_ENDPOINT_RESPONSE_ALERT,
} from '@/lib/constants';
import scheduleModule from '@/store/modules/scheduleModule';
import { Debounce } from 'vue-debounce-decorator';
import metaDataModule from '@/store/modules/metaDataModule';
import gptModule from '@/store/modules/gptModule';
import GptMixin from '../gpt/GptMixin';


@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    TasqsVerticalList: () => getComponent('tasqs/TasqsVerticalList'),
    TasqFeedback: () => getComponent('gpt/TasqFeedbackV6'),
    TasqLoader: () => getComponent('loaders/TasqLoader'),
    TasqItem: () => getComponent('tasqs/TasqItem'),
    manualTasq: () => getComponent('gpt/GptTasqManualPopup'),
    WellSignalsView: () => getComponent('tasqs/WellSignalsView'),
    PadSignalView: () => getComponent('tasqs/PadSignalView'),
    Multiselect,
    DateRangePicker,
  },
})
export default class TasqDetailsMixin extends mixins(DataLoading, TasqMixin, GptMixin) {
  isLoadingSignals = true

  tasqOptionsOpen = false;

  chartsLoading = false;

  showAddManualTasq = false;

  tag: any = '';

  tags: any = [];

  activeTableID: any = '';

  showLinkSharePopup = false;

  activeWorkspaceID: any = '';

  showChartModal: any = false;

  autoCompleteTags: any = [];

  addTasqAssetType = ''

  equipment: any = '';

  equipments: any = [];

  signalChartTime = 60;

  loadingTasqs = false;

  isOnlineApp = true;

  actionLoadingInProcess = false;

  showComment = false;

  multiTasqType = '';

  subPredictionList: any = [];

  comment = '';

  visiblePadWellsFrom = 0

  visiblePadWellsTo = 5

  showActionDialog: boolean = false;

  showDataLoadingFeedback = false

  actionErrors: any = [];

  testSlowItDown = false

  get creatorFullname() {
    // @ts-ignore
    return getNameByEmail(this.activeTasq?.createdBy);
  }

  get formattedTime() {
    const time = convertUTCDateToLocalDate(this.activeTasq.time)
    return `${time.toDateString()} ${time.toLocaleTimeString()}`;
  }

  get showWellEvents() {
    return tasqProductionDataChartModule.showWellEvents;
  }

  get isEditing() {
    return tasqsListModule.isEditing;
  }

  get isCalendarView() {
    return tasqsListModule.isCalendarView && tasqsListModule.tasqListLevel === 'WorkTicket';
  }

  get disabledDates() {
    const tasqDetailComponent = this;
    return [
      function (date) {
        const startDate = new Date(tasqDetailComponent.action.startDate);
        startDate.setHours(0, 0, 0, 0);
        date.setHours(0, 0, 0, 0);
        return date <= startDate;
      },
    ];
  }

  addTasqDidSelectAssetTasq() {
  }

  copyLink(type, wellName = '', tasqListLevel: any = 'tasq') {
    if (type === 'tasq') {
      navigator.clipboard.writeText(window.location.href);
    } else if (type === 'well') {
      const url = `${window.location.origin}/Tasqs/${encodeURI(wellName)}?type=producing&view=wellview`;
      navigator.clipboard.writeText(url);
    } else if (type === 'pad') {
      const url = `${window.location.origin}/Tasqs/${encodeURI(wellName)}?&view=padview`;
      navigator.clipboard.writeText(url);
    }

    this.showLinkSharePopup = false;
  }

  closeEditToolbar() {
    tasqsListModule.setIsEditing(false);
    tasqsListModule.setIsBatchResponding(false);
    tasqsListModule.setIsBatchReassign(false);
    tasqsListModule.setActiveTasq('');
    tasqsListModule.resetCheckedTasqs();
  }

  openAddTag() {
    // @ts-ignore
    document.querySelector('.multiselect-customNew input').style.background = 'transparent';
    // @ts-ignore
    document.querySelector('.multiselect-customNew input').style.maxWidth = '100px';
    const element: any = document.querySelector('.multiselect-customNew');
    const element2 = element.querySelector('input');
    if (element) {
      const dropdown = element.querySelector('.multiselect__content-wrapper');
      const cor = element.getBoundingClientRect();
      const top = cor.top - 300;
      // console.log(cor)
      dropdown.style.left = `${cor.x}px`;
    }
  }

  closeAddTag() {
    // @ts-ignore
    document.querySelector('.multiselect-customNew input').style.background = '#495364';
    // @ts-ignore
    document.querySelector('.multiselect-customNew input').style.display = 'block';
  }

  closeShareableDialog() {
    this.showLinkSharePopup = false;
  }

  async chartModalSetup() {
    this.showChartModal = false;
    const elements = document.querySelectorAll('.tasqs-grid')![0];
    if (elements) {
      //  @ts-ignore
      elements.style.gridTemplateColumns = 'auto 400px';
    }
  }

  get productionData() {
    return tasqProductionDataChartModule.productionData;
  }

  get isBatchResponding() {
    return tasqsListModule.isBatchResponding;
  }

  get adminUser() {
    return workflowModule.user.adminChartAccess;
  }

  padWellsSkipToEnd() {
    this.visiblePadWellsFrom = (Math.ceil(this.activeTasq?.wells.length / 6) - 1) * 6;
    this.visiblePadWellsTo = this.visiblePadWellsFrom + 5;
  }

  padWellsNext() {
    if (this.visiblePadWellsTo >= this.activeTasq?.wells.length || this.visiblePadWellsFrom + 6 >= this.activeTasq?.wells.length) {
      return;
    }
    this.visiblePadWellsFrom += 6;
    this.visiblePadWellsTo += 6;
  }

  padWellsPrevious() {
    if (this.visiblePadWellsFrom - 6 < 0) {
      return;
    }
    this.visiblePadWellsFrom -= 6;
    this.visiblePadWellsTo -= 6;
  }

  padWellsSkipToBeginning() {
    this.visiblePadWellsFrom = 0;
    this.visiblePadWellsTo = 5;
  }

  get tasqs(): TasqJob[] {
    return tasqsListModule.allTasqs;
  }

  get activeTasqs(): TasqJob[] {
    return tasqsListModule.tasqListLevel === 'Pad' ? tasqsListModule.padTasqList: tasqsListModule.tasqList;
  }

  get wellTasqs(): TasqJob[] {
    return tasqsListModule.additionalPadNestedTasqs;
  }

  get producingWells(): TasqJob[] {
    return tasqsListModule.producingWells;
  }


  filterAndSortByWellNameAndPredictionType(data) {
    // Function to get priority of predictionType
    const getPredictionTypePriority = (predictionType) => {
        const priority = {
            'Off-Target RT': 1,
            'Off-Target': 2,
            Producing: 4,
        };
        return priority[predictionType] || 3; // default priority for unknown types
    };

    // Sort the array based on wellName and predictionType
    data.sort((a, b) => {
        if (a.wellName === b.wellName) {
            return getPredictionTypePriority(a.predictionType) - getPredictionTypePriority(b.predictionType);
        }
        return a.wellName.localeCompare(b.wellName);
    });

    // Reduce the array to unique wellNames with priority for predictionType
    return data.reduce((acc, current) => {
        if (!acc.some((item) => item.wellName === current.wellName)) {
            acc.push(current);
        }
        return acc;
    }, []);
}

  get getWells() {
    let responseData: any[] = [];
    if (this.activeTasq != null) {

      // @ts-ignore
      if (this.tasqListLevel.toLowerCase() === 'pad' && this.routeQueryView === 'padview') {
        if(this.activeTasq && this.activeTasq.wells && this.activeTasq.wells.length > 0){
          let data: any = JSON.parse(JSON.stringify(this.activeTasq.wells))
          const uniqueWells: any = this.filterAndSortByWellNameAndPredictionType(data)
          return uniqueWells
        }

      }
    }
    return responseData;

  }

  get tasqListLevel() {
    return tasqsListModule.tasqListLevel.toLowerCase();
  }

  get isProductionDataLoaded() {
    return tasqProductionDataChartModule.productionData.loaded;
  }

  get currentWellType(): any {
    return this.$route.query.type;
  }

  get currentSignals(): any {
    return tasqSignalsModule.currentSignals;
  }

  get productionDataDict(): any {
    return tasqProductionDataChartModule.productionDataDict;
  }

  async tagsAdded(newTag) {
    this.tags.push(newTag.toLowerCase());
    //  newTags.push(newTag)
    await scheduleModule.addTagsToWell({ node_id: this.activeTasq?.wellName, tags: this.tags });
  }

  async tagsChanged(newTag) {
  //  let newTags = this.tags;
    //  newTags.push(newTag)
    await scheduleModule.addTagsToWell({ node_id: this.activeTasq?.wellName, tags: [newTag.toLowerCase()].concat(this.tags) });
  }

  async tagsRemove(removeT) {
    const index = this.tags.indexOf(removeT);
    if (index > -1) {
      this.tags.splice(index, 1);
    }
    await scheduleModule.addTagsToWell({ node_id: this.activeTasq?.wellName, tags: this.tags });
  }

  get routeQueryType() {
    return this.$route.query.type;
  }

  get routeQueryView(): any {
    let currentView: any = this.$route.query.view || ''
    return currentView;
  }

  getPredictionList() {
    this.subPredictionList = [];
    if (this.activeTasq && this.activeTasq.predictionsList && Object.keys(this.activeTasq.predictionsList) && this.routeQueryType !== 'producing') {
      this.activePredictionItem = this.activeTasq.id;
      let found = false;
      this.subPredictionList = Object.keys(this.activeTasq.predictionsList).map((data) => {
        const object:any = this.activeTasq?.predictionsList[data];
        if (data === this.activeTasq?.overriddenName) {
          found = true;
        }
        if (data === 'Off-Target RT') {
          data = 'REAL-Time';
        }
        return { name: data, predictionId: object.PredictionID };
      });
      if (!found) {
        this.subPredictionList.push({ name: this.activeTasq.engineerType, predictionId: this.activeTasq.id });
      }
    } else {
      this.subPredictionList = [];
    }
  }


  get tasq(){
    return tasqsListModule.activeTasq
   }


  get wellWaitingOn() {
    let padWaitingOn = null;
    const wellWaitingOn = gptModule.waitingOnStatusByWellName(this.activeTasq.wellName);
    const padName = this.activeTasq && this.activeTasq.padName ? this.activeTasq.padName : this.tasq && this.tasq.padName ?  this.tasq.padName : null
    if (padName) {
       padWaitingOn = gptModule.waitingOnStatusByWellName(padName);
    }
    return this.getRcentWaitingOnStatus({ wellWaitingOn, padWaitingOn });
  }


  get activeTasq() {
    if (this.isEditing || tasqsListModule.checkedTasqs.length) {
      if (!tasqsListModule.activeTasq) {
        tasqsListModule.setActiveTasq(tasqsListModule.checkedTasqs[0]);
      }
      return tasqsListModule.activeTasq as TasqJob;
    }
    if (this.$route.query.type == 'id' && this.$route.params.id != null) {
      assetsModule.setActiveProducingTasq('');
      return tasqsListModule.activeTasq as TasqJob;
    }
    if (tasqsListModule.activeTasq != null && this.$route.params.id != null && this.$route.query.type == 'producing' && tasqsListModule.activeTasq.level.toLowerCase() == 'pad') {
    return tasqsListModule.activeTasq;
    }
    if (assetsModule.activeTasq == undefined) {
      return tasqsListModule.activeTasq as TasqJob;
    }
    return assetsModule.activeTasq as TasqJob;
  }

  get reassignUsers() {
    return accountModule.reassignmentList.map((i) => ({
      // @ts-ignore
      text: i.name,
      value: i.email,
    })).sort((a, b) => a.text.localeCompare(b.text));
  }

  get enabledPads() {
    return assetsModule.enabledPads;
  }

  get enabledWells() {
    return assetsModule.enabledWells;
  }

  get enabledWellsMapping() {
    return assetsModule.enabledWells.map((well) => ({ text: well.text, value: well.value }));
  }

  resetTasq() {
    tasqFeedbackModule.resetAllData();
  }

  @Debounce(1000)
  showEventCommentPopup(comment) {
    console.log(comment);
    if (comment) {
      this.wellEventDetails = {

        createdBy: comment.Username ? getNameByEmail(comment.Username) : 'N/A',
        createdDate: comment.time,
        ...comment,
        jobType: comment.JobType || comment.PredictionType,
      };
      this.showWellEventDetail = true;
    }
  }

  get waitingOnOptions() {
    return metaDataModule.waitingOnList;
  }

  localHistoryEvent = true;

  isLoadingFirstTimeDone = false

  async initializeTasqDetails() {
    await metaDataModule.getActionsBuilder();
    tasqsListModule.setActivePage('Tasq');

    this.isOnlineApp = await isGoodService();
    this.sleep(5000).then(() => {
      const promiseList: any = [];
      // promiseList.push(assetsModule.getEnabledPads());
      promiseList.push(metaDataModule.getActionsBuilder());
      promiseList.push(accountModule.getReassignmentList({ useExisting: true }));
      promiseList.push(assetsModule.getEnabledWells());
      Promise.all(promiseList).then(async () => {
        // console.log('resolved');
        if (this.isOnlineApp) {
          workspaceModule.getWorkspaces(null);
          if (!accountModule.didPreloadData) {
            // await preloadData();

          }
          datadogRum.init({
            applicationId: '42a5cbbe-cee7-4b16-b31c-27f8330f507b',
            clientToken: 'pubb848c6a76f8811fde5cd24dc0264f13f',
            site: 'datadoghq.com',
            service: 'tasq',
            env: getConfigEnv('BRANCH_NAME'),
            // Specify a version number to identify the deployed version of your application in Datadog
            version: accountModule.user.email,
            sessionSampleRate: 100,
            sessionReplaySampleRate: 100,
            trackResources: true,
            trackLongTasks: true,
            trackUserInteractions: true,
            defaultPrivacyLevel: 'allow',
          });
          datadogRum.setUser({email: workflowModule.user.email, name: workflowModule.user.username});
          datadogRum.startSessionReplayRecording();

        }
        // await tasqsListModule.getTasqListResponses();
      }, (err) => {
        console.log('Error:');
        console.log(err);
      });
    });
  }

  get currentViewType() {
    return this.$route.query.view;
  }

  async loadProducingWellData() {
    if (this.$route.params.id && this.$route.query.view === 'padview') {
      // await workflowModule.getWellStatuses(this.$route.params.id)
      const fetchTasq = await tasqsListModule.searchTasqByName({ level: 'Pad', wellName: this.$route.params.id });
      tasqsListModule.setActiveTasqExplicit(fetchTasq);
      assetsModule.setActiveProducingTasq(fetchTasq);
      await this.fetchTasq(null, false, false);
    } if (this.$route.params.id && this.$route.query.view === 'wellview') {
      // await workflowModule.getWellStatuses(this.$route.params.id)
      const fetchTasq = await tasqsListModule.searchTasqByName({ level: 'Well', wellName: this.$route.params.id });
      tasqsListModule.setActiveTasqExplicit(fetchTasq);
      assetsModule.setActiveProducingTasq(fetchTasq);
      await this.fetchTasq(null, false, false);
    } else if (this.$route.query.type === 'producing' && this.$route.params.id) {
      // await workflowModule.getWellStatuses(this.$route.params.id)
      const fetchTasq = await tasqsListModule.searchTasqByName({ level: 'Well', wellName: this.$route.params.id });
      if (fetchTasq) {
        tasqsListModule.setActiveTasqExplicit(fetchTasq);
        assetsModule.setActiveProducingTasq(fetchTasq);
        await this.fetchTasq(null, false, false);
      }
    }
    else if (this.$route.query.type === 'id' && this.$route.params.id) {
      await this.fetchTasq(null, false, true);
    }

    this.isLoadingFirstTimeDone = true
  }

  async fetchTasqAsync() {
    if (this.$route.params.id != null && this.$route.params.id != '' && this.$route.query.type == 'id') {
      await this.fetchTasq();
    }
  }

  beforeDestroy() {
    tasqsListModule.setActiveTasq('');
  }

  editTasqs() {
    const currentName: any = this.$route.name;
    this.$router.push({ name: currentName });
    tasqsListModule.setIsLoadingTasqResponses(false);
    if (tasqsListModule.isEditing) {
      this.closeEditToolbar();
    } else {
      tasqsListModule.setActiveTasq('');
      tasqsListModule.setIsEditing(true);
    }
  }

  activePredictionItem: any = ''

  async updateTasqPrediction(data) {
    assetsModule.setActiveProducingTasq('');
    window.history.pushState('tasqs', 'Title', `/tasqs/${data.predictionId}?type=id`);
    this.multiTasqType = data.name;
    this.showDataLoadingFeedback = true;
    workflowModule.setV2ResponseData(null);
    tasqFeedbackModule.resetAllData();
    this.activePredictionItem = data.predictionId;
    await this.fetchTasq(data.predictionId, true);
    this.showDataLoadingFeedback = false;
  }

  async editWaitingOnStatus() {
    let action: any = {}
    if (this.activeTasq && this.activeTasq.waitingOnStartDate && this.activeTasq.waitingOnEndDate) {
    // console.log(this.activeTasq.snoozed.WaitStartDate);
      action.type = 'wait';
      action.startDate = this.activeTasq?.snoozed.WaitStartDate;
      action.date = this.activeTasq?.snoozed.UntilDate;
      if (this.activeTasq.waitUntilReason) {
        action.reason = this.activeTasq?.waitUntilReason.split('-Remarks:')[0];
      }


      // action.comment = this.activeTasq.waitUntilReason.split('-')[1];
      action.title = `"Please select a status for the selected well(s)`;
      action.confirmText = 'Update';
      this.action = action;
      this.$dialog.show('actionModal');
    }
  }


  prepareAction(action) {
    this.showActionDialog = true;
    this.action = {
      type: '',
      title: '',
      confirmText: '',
      reason: '',
      person: '',
      comment: '',
      date: '',
      well: '',
      startDate: new Date().toISOString(),
      jobType: '',
      frequency: 'Today',
      delayedDays: 0,
    };
    switch (action) {
      case ('reject'):
        this.action.type = action;
        this.action.title = `Are you sure you want to reject ${this.activeTasq?.wellName}?`;
        this.action.confirmText = 'Yes, reject it';
        this.$dialog.show('actionModal');
        break;
      case ('reassign'):
        this.action.type = action;
        this.action.title = `Select the user to reassign for the selected well(s):`;
        this.action.confirmText = 'Reassign tasq';
        this.$dialog.show('actionModal');
        break;
      case ('wait'):
        this.action.type = action;
        this.action.title = `Please select a status for the selected well(s):`;
        this.action.confirmText = 'Update';
        this.$dialog.show('actionModal');
        break;
      case ('add'):
        this.showAddManualTasq = true;
        break;
      default:
        break;
    }
  }

  openDialog() {
    this.sleep(300).then(() => {
      this.$dialog.show('actionModal');
    });
  }

  validateDialog(type) {
    if (type === 'reassign') {
      if (!this.action.person) {
        this.actionErrors.push('person');
      }
    } else if (type === 'wait') {
      if (!this.action.date) {
        this.actionErrors.push('date');
      }
      if (!this.action.startDate) {
        this.actionErrors.push('startDate');
      }
      if (!this.action.reason) {
        this.actionErrors.push('reason');
      }
      if (this.action.date && this.action.startDate && this.action.date < this.action.startDate) {
        this.actionErrors.push('invalidEndDate');
      }
    } else if (type === 'reject') {
      if (!this.action.reason) {
        this.actionErrors.push('reason');
      }
    } else if (type === 'add') {
      if (!this.addTasqAssetType) {
        this.actionErrors.push('addTasqAssetType');
      } else {
        if (!this.action.jobType) {
          this.actionErrors.push('jobType');
        }
        if (!this.action.person) {
          this.actionErrors.push('person');
        }
        if (!this.action.well) {
          this.actionErrors.push('well');
        }
      }
    }
  }

  async onActionDialogClosed(response) {
    let successText = ''!;
    this.actionErrors = [];
    const tasqID = this.activeTasq?.id;
    if (response.isOk) {
      this.validateDialog(this.action.type);
      if (this.actionErrors.length) {
        this.openDialog();
        return;
      }
      switch (this.action.type) {
        case ('reassign'):
          successText = 'Successfully reassigned!';
          await tasqActionsModule.reassignTasq(this.action);
          tasqsListModule.resetSetActiveTasq();
          break;
        case ('reject'):
          successText = 'Successfully Rejected!';
          await tasqActionsModule.rejectTasq(this.action);
          tasqsListModule.resetSetActiveTasq();
          break;
        case ('wait'):
          try{

            this.actionLoadingInProcess = true

            successText = 'Successfully added to waitlist!';
            const operatorDetails =  this.operatorDetails
            this.action.wellMetaData =   {
              "operator_name":operatorDetails['operatorName'],
              "operator_id": operatorDetails['operatorID'],
              "nodeid":  this.activeTasq.wellName,
              "level": this.activeTasq.level
          };
            await tasqActionsModule.updateTasqStatus(this.action);
            tasqsListModule.resetSetActiveTasq();

          }catch(error){
            console.log(error);
          }finally{
            this.actionLoadingInProcess = false
          }
          break;
        case ('add'):
          successText = 'Tasq added successfully!';
      this.action.assetType = this.addTasqAssetType;
          await tasqActionsModule.addTasq(this.action);
          tasqsListModule.resetSetActiveTasq();
          break;
        default:
          break;
      }
      this.closeEditToolbar();


     if(this.action.type !== 'wait'){
      this.action = {};
      this.showActionDialog = false;
      await tasqsListModule.getRawTasq({ PredictionID: tasqID });
      const currentName: any = this.$route.name;
      this.$router.push({ name: currentName });
      tasqsListModule.setIsLoadingTasqs(true);
      await sleep(100)
      this.$nextTick(() => {
        tasqsListModule.setIsLoadingTasqs(false);
      });
     }else{
      this.action = {};
      this.showActionDialog = false;
     }
      if (successText) {
        this.$eventBus.$emit(SHOW_ALERT, successText);
      }
    }
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async fetchTasq(predictionID = null, loadingFromSubPrediction = false, fetchData = true) {
    // this.chartModalSetup();
    this.showDataLoadingFeedback = true;
    this.showLinkSharePopup = false;

    tasqsListModule.setTasqEventHistoryList([]);
    workflowModule.setWellHistory([]);
    workflowModule.resetWellHistoryEvents();
    this.signalChartTime = 60;
    if (!loadingFromSubPrediction) {
      this.activePredictionItem = '';
    }

    this.isOnlineApp = navigator.onLine;
    workflowModule.setV2ResponseData(null);
    tasqsListModule.setFromNowBack(0);

    if (fetchData) {
      if (predictionID != null) {
      // @ts-ignore
        if (!loadingFromSubPrediction) {
        // @ts-ignore
          tasqsListModule.setActiveTasq(predictionID);
          if (tasqsListModule.activeTasq == null) {
            if (this.tasqListLevel.toLowerCase()) {
              await tasqsListModule.getTasq({
                PredictionID: this.$route.params.id,
                updateTasqInList: false
              });
            }
          }
        }
      } else if (this.$route.params.id != null && this.$route.params.id != '' && this.$route.query.type == 'id') {

        if (!this.isOnlineApp) {
           tasqsListModule.setActiveTasq(this.$route.params.id);
        } else if (this.tasqListLevel.toLowerCase()) {
          tasqsListModule.setActiveTasq(this.$route.params.id);
          if(!this.activeTasq){
            await tasqsListModule.getTasq({
              PredictionID: this.$route.params.id,
              updateTasqInList: false
            });
          }

        }
      } else {
        assetsModule.setActiveProducingTasq(this.$route.params.id);
      }
    }
    if(tasqsListModule.activeTasq === undefined){
      await this.loadProducingWellData()
    }
    tasqProductionDataChartModule.getNodeMeta({ assetName: tasqsListModule.activeTasq.wellName }).then(() => {

    });
    localStorage.setItem('activeTasqName', tasqsListModule.activeTasq.wellName )
    this.showDataLoadingFeedback = false;
  }

  setVerticalListPinnedValue(){
    tasqsListModule.setIsVerticalListUnPinned(!tasqsListModule.isVerticalListUnPinned)
  }

  @Debounce(100)
  setVerticalListHovering(state){
    tasqsListModule.setIsVerticalListHovering(state)
  }
}
